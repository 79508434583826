body,
div,
p {
    font-size: 14px;
    font-family: "Lato", sans-serif;
    margin: 0;
    padding: 0;
}

$icafal-orange: #929292;
$icafal-orange-dark: #705401;
$icafal-background: #f2f2f2;
.-header {
    background-color: $icafal-orange;
    color: #fff;
    display: none;
}

.rt-td {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rt-resizable-header-content {
    padding: 4px 0;
}

.rt-th input,
.table-select-top {
    border: solid 1px #999 !important;
}

.modal-header .close {
    outline: 0;
}

.form-actions {
    display: flex;
    justify-content: space-around !important;
    width: 100% !important;
    button {
        background-color: rgba(0, 0, 0, 0);
        border: 0;
        .fa-exclamation-triangle {
            color: red;
        }
        &:focus {
            outline: 0;
        }
    }
    a {
        color: rgb(41, 41, 41);
        font-size: 1.2em;
    }
}

.form-footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 30px;
    .spinner {
        margin-right: 20px;
    }
    button {
        width: 200px;
    }
}

.simple-loading-container {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
}

.padding10 {
    padding: 10px;
}

.form-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        border: none;
        background-color: transparent;
    }
    .fa-exclamation-triangle {
        color: #ff5900;
    }
    .fa-check-circle {
        color: green;
    }
}

.form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url('data:image/svg + xml,%3csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 16 16"%3e%3cpathfill="none"stroke="%23343a40"stroke-linecap="round"stroke-linejoin="round"stroke-width="2"d="M2 5l6 6 6-6"/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.bg-color-white {
    background-color: #fff;
}
