.esandbox-content {
    padding: 30px;
}

pre {
    background: #FFF;
    border: 2px solid #3e3e3e;
    border-radius: 5px;
    padding: 10px;
    margin: 10px !important;
}

.select-css {
    margin-bottom: 10px ;
}

.datepicker {
    input {
        border: 1px solid #868686
    }
}