.token-content {
    margin: 20px 50px;
    background-color: #FFF;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #8e8e8e
}

.code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: .9em;
    padding: 5px;
    background-color: #e3e3e3;
    margin: 10px 0;
    border-radius: 3px;
    border: 1px solid #8e8e8e
}