.image-component {
    width: 100%;
    cursor: pointer;
    margin: 5px;
    .view {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            max-width: 80%;
            box-shadow: 3px 3px 6px rgb(199, 199, 199);
        }
    }

    .fullscreen {
        position: fixed;
        z-index: 10000;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.666);
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;
        img {
            max-width: 80%;
            max-height: 90%;
            border-radius: 4px;
            padding: 20px;
        }
    }
}