.container-uploader {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;

    @media (max-width: 767px) {
        justify-content: space-between;
    }

    &__empty,
    &__image {
        border: 2px dashed #767676;
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
        position: relative;
        cursor: pointer;

        input {
            width: 100%;
            height: 100%;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
        }

        @media (max-width: 767px) {
            width: 130px;
            height: 130px;
        }
    }

    &__image {
        border: 1px solid;
        border-radius: 5px;
        overflow: hidden;

        i {
            position: absolute;
            z-index: 10;
            top: 10px;
            right: 10px;
            color: red;
            font-size: 1.5em;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}
