.esandbox-content {
    padding: 30px;
}

pre {
    background: #FFF;
    border: 2px solid #3e3e3e;
    border-radius: 5px;
    padding: 10px;
    margin: 10px !important;
}

.select-css {
    margin-bottom: 10px ;
}

.datepicker {
    input {
        border: 1px solid #868686
    }
}

.row {
    .title {
        font-size: 2em;
        margin-bottom: 15px;
        color: #868686;
    }
}

.boxes {
    justify-content: center;
    color: #868686;
    border: 2px solid #ffa6007b;
    border-radius: 8px;
    padding: 10px;
    
    .col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-size: 2em;
        padding: 20px 5px;
        cursor: pointer;
        img {
            width: calc(200px * 100 / 100);
            max-width: 300px;
        }
    }

    .col.Chica {
        img {
            width: calc(200px * 60 / 100);
        }
    }

    .col.Mediana {
        img {
            width: calc(200px * 75 / 100);
        }
    }

    .col.Grande {
        img {
            width: calc(200px * 95 / 100);
        }
    }
    
    .col.selected {
        background-color: #ffa6003d;
        border-radius: 8px;
        box-shadow: 3px 3px 5px #e3e3e3;
    }
}