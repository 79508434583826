.folder {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 20px;
    font-size: vmax(1);
    cursor: pointer;
    border-radius: 8px;
    margin: 10px 0;
    text-align: center;
    position: relative;

    i {
        position: absolute;
        top: 45%;
        font-size: 1.6em;
        opacity: .4;
    }

    &:hover {
        background-color: rgba(40, 80, 153, 0.111);
    }

    img {
        width: 100%;
    }

    @media(max-width: 500px) {
        width: 80%;
    }

    @media(max-width: 1200px) {
        i {
            display: none;
        }
    }
    
}