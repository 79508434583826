@import "../../styles.scss";

.read-only-container {
  .label {
    font-weight: bold;
  }

  .read-only-value {
    padding-bottom: 20px;
    padding-left: 5px;
  }
}

.select-container {
  position: relative;

  select {
    border: solid $icafal-orange 1px;
    border-radius: 3px;
    display: block;
    height: 38px;
    margin-bottom: 20px;
    padding-left: 10px;
    width: 100%;
  }

  .border-error {
    border: solid red 3px;
    border-radius: 4px;
  }

  .warning {
    bottom: -16px;
    color: red;
    font-size: 0.8em;
    font-weight: bold;
    position: absolute;
    right: 0;
  }

  .padding-icon {
    padding-left: 40px;
  }

  .loading {
    position: absolute;
    right: 20px;
    top: 29px;
  }
}
