.sidelocker-img {
    height: 0;
    overflow: auto;
    transition: height ease .3s;

    img {
        border: 1px solid #3e3e3e;
        border-radius: 5px;
        padding: 5px 0;
    }
}

.sidelocker-img.open {
    height: 720px !important;
    transition: height ease .3s;
}