.container-ecommerce {
    width: 100%;
    min-height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width: 550px) {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .content {
        background-color: #FFF;
        border-radius: 4px;
        margin: 15px 0;
        box-shadow: 3px 3px 7px #e3e3e3;
        padding: 20px;
        width: 90%;

        @media(max-width:550px) {
            width: 100%;
        }

        .checkbox {
            padding: 15px 30px;
            border: 1px solid #e3e3e3;
            margin: 5px 1px;
            border-radius: 3px;
            input[type=checkbox] {
                margin-right: 8px;
            }
        }
    }
}