.avatar-container {
  margin-bottom: 40px;
  margin-top: 40px;
  text-align: center;

  img {
    margin-bottom: 15px;
    width: 55px;
  }

  .title {
    font-weight: bold;
  }
}
