.rt-td {
    white-space: normal !important;
    width: 100%;
    text-align: center;
}

.info {
    padding: 5px;
    font-weight: 600;
    line-height: 23px;
}
