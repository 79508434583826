@import "../../styles.scss";

.button-main {
  color: white;
  background-color: $icafal-orange;
  border: none;
  border-radius: 2px;
  display: block;
  font-weight: bold;
  height: 35px;
  text-transform: uppercase;
  width: 100%;

  &:hover:not([disabled]) {
    background-color: #545454;
    cursor: pointer;
  }

  &:disabled {
    cursor: auto;
    background-color: #54545464;
  }
}

.align-btn {
  margin-top: 21px;
}
