.loader-overlay{
	align-items: center;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	height: 100vh;
	justify-content: center;
	position: absolute;
	width: 100%;
	z-index: 1000;

	.loader-spinner{
		color: white;
		height: 100px;
		text-align: center;
		width: 100px;
	}
}

