.manuals {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: #FFF;
    margin-top: 20px;
    border-radius: 5px;

    h3 {
        border-bottom: 1px solid #d1d1d1;
        color: rgb(85, 85, 85);
        margin-top: 30px;
        padding-bottom: 3px;
        padding-left: 5px;
    }

    p {
        padding: 15px 15px 3px 15px;
        font-size: 1.1rem !important;

        span {
            text-decoration: underline;
            font-weight: bold;
        }

    }

    ul li {
        padding: 10px 5px;
        border-bottom: 1px solid rgb(225, 225, 225);
    }
}