.section {
    display: flex;
    justify-content: center;
    min-height: 90vh;
    padding: 20px;
    flex-direction: column;
    .tabs {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #ddd;

        .tabItem {
            padding: 5px 25px;
            border-radius: 3px 3px 0 0;
            cursor: pointer;

            &:hover {
                background-color: #ddd;
            }
        }

        .tabItem.active {
            margin-bottom: -1px;
            padding: 5px 25px;
            background-color: #fff;
            border: 1px solid #ddd;
            border-bottom: transparent;
        }
    }

    .content {
        border: 1px solid #DDD;
        border-top: transparent;
        padding: 20px;
        border-radius: 0 0 5px 5px;
        min-height: 80vh;
        background-color: #FFF;
    }
}